.panel {
  background-color: #191B1E;
  color: #6f6f6f;
  --panel-main-text-color: #fff;
  --head-padding: 24px;
  --panel-head-border-color: #2B2C2F;

  &__head {
    padding: 0 var(--head-padding);
  }

  &__head-content {
    border-bottom: 1px solid var(--panel-head-border-color);
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__caption {

  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    color: var(--panel-main-text-color);
  }

  &__body {

  }

  &__scroll {
    height: calc(100vh - 240px);

    &_headless {
      height: calc(100vh - 180px);
    }
  }

  &__filter {
    position: relative;
  }

  &__filter-link {
    color: var(--panel-main-text-color);
    font-size: 15px;
  }

  &__filter-info {
    position: absolute;
    z-index: 10;
    top: 90%;
    right: 0;
    background-color: #fff;
    padding: 20px;
    min-width: 200px;
    box-shadow: 0 0 10px -3px;
  }

  &_light {
    background-color: transparent;
    color: #6f6f6f;
    --panel-main-text-color: #191B1E;
    --panel-head-border-color: #EAECF2;
  }

  &_embedded {
    --head-padding: 0;
  }
}