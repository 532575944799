
.task-project {
    position: relative;
}
.task-project-select {
    padding-bottom: 20px;
    position: absolute;
    min-height: 200px;
    width: 100%;
    background-color: #ffffff;
    z-index: 3;
    -webkit-box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
    box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
}
