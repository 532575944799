
.deadline-datepicker {
  .theme-grey {
    input {
      padding-left: 54px;
    }
  }
}

.isExpired {
  background: #FFECF0;

  input {
    background: #FFECF0;
    color: #FF234C;
  }
}

.isLocked {
  background: #f1ffec;

  input {
    background: #f1ffec;
    color: #538611;
  }
}
