
.options-tag {
  &__link {
    display: flex;
    height: 37px;
    padding: 0 24px;
    align-items: center;

    &:hover {
      background-color: #F1F3F8;
    }
  }
  &__circle {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    margin-right: 9px;
  }
  &__name {
    display: block;
    flex-grow: 1;
    font-size: 15px;
  }
  &__avatar {
    display: block;
    margin-right: 9px;
  }
}
