.file {
  display: flex;
  align-items: center;

  &__link-download {
    margin-right: 10px;

    svg {
      box-shadow: 0px 19px 30px -5px rgba(0,0,0,0.2);
    }
  }

  &__icon-sketch {
    display: flex;

  }

  &__icon-word {

  }

  &__icon-ps {

  }

  &__icon-winzip {

  }

  &__wrapper-label {
    display: flex;
    align-items: center;
  }

  &__new-wrapper-label {
    display: flex;
    margin-top: 18px;
    margin-left: -10px;
  }

  &__link-name {
    cursor: pointer;
  }

  &__name-file {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    font-size: 15px;
    color: $color-alert;
  }

  &__name-size {
    font-size: 13px;
  }

  &__img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 0;
  }
}