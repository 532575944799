
.options-worker {
  &__link {
    display: flex;
    padding: 6px 24px;
    align-items: center;

    &:hover {
      background-color: #F1F3F8;
    }
  }

  &__name {
    display: block;
    flex-grow: 1;
    font-size: 15px;
    color: #3A3E44;
  }

  &__avatar {
    display: block;
    margin-right: 9px;
  }
}

