
.avatar-list {
	display: flex;
	align-items: center;

	&__item {
		margin-right: 6px;

		&:last-child {
			margin-right: 0;
		}
	}
}
