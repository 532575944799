.files-list {

  &__link-download {
    margin-right: 10px;
    svg {
      box-shadow: 0px 19px 30px -5px rgba(0,0,0,0.2);
    }
  }

  &__icon-sketch {
    display: flex;

  }

  &__icon-word {

  }

  &__icon-ps {

  }

  &__icon-winzip {

  }

  &__wrapper-label {
    display: flex;
    align-items: center;

  }

  &__new-wrapper-label {
    display: flex;
    margin-top: 18px;
    margin-left: -10px;
  }

  &__link-name {
    cursor: pointer;
  }

  &__name-file {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    font-size: 15px;
    color: #3A3E44;
  }

  &__name-size {
    color:  $color-border4;
  }

  &__delete {
    margin-left: 15px;
    align-self: flex-start;
    padding-top: 3px;
  }
}