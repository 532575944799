.todo-list {
  &::-webkit-scrollbar {
    display: none;
  }

  &__inner {
    background-color: $color-primary;
    height: max-content;
    max-width: 834px;
  }

  &__list {
    padding: 15px 30px;
  }

  &__item {
    &:hover {
      .todo-list__edits {
        opacity: 1;
      }
    }
  }

  &__item-main {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    appearance: none;
    background-color: transparent;
    margin: 0;

    font: inherit;
    color: var(--primary-color);
    width: 22px;
    height: 22px;
    border: 1px solid rgba(255, 255, 255, .4);
    border-radius: 100%;
    transition: background .3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      display: block;
      width: 22px;
      height: 22px;
      transition: background-image .3s var(--default-transition-function);
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked {
      &::before {
        background-image: url('../images/svg/checkbox-round-red-checked.svg');
      }

      + label {
        color: rgba(255, 255, 255, .5);
        text-decoration: line-through;
      }
    }
  }

  label {
    padding-left: 34px;
    color: #fff;
    cursor: pointer;
    transition: color .3s ease-in-out;
  }

  &__edits {
    display: flex;
    align-items: center;
    margin-left: 15px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &__edit-todo,
  &__remove-todo {
    display: flex;
    background: rgba(255, 255, 255, .1);
    border-radius: 2px;
    width: 22px;
    height: 22px;
    transition: background-color .3s ease-in-out;

    &:hover {
      background: rgba(255, 255, 255, .15);
    }
  }

  &__edit-icon,
  &__item-edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__remove-todo {
    margin-left: 4px;
  }

  &__item-edit {
    position: relative;
  }

  &__item-edits {
    position: absolute;
    top: 8px;
    right: 7px;
    display: flex;
    align-items: center;
  }

  &__item-edit-submit,
  &__item-edit-cancel {
    display: flex;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    transition: background-color .3s ease-in-out;
  }

  &__item-edit-submit {
    background-color: $color-alert;

    &:hover {
      background-color: #E32145;
    }
  }

  &__item-edit-cancel {
    background-color: rgba(255, 255, 255, .1);
    margin-left: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, .15);
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, .05);
    padding-right: 10px;
  }

  &__textarea-new {
    flex: 0 1 100%;
    color: #fff;
    background-color: $color-primary;
    border: unset;
    padding: 17px 30px 22px;
    font-family: $font;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &._error {
      &::-webkit-input-placeholder {
        color: $color-alert;
      }
    }
  }

  &__textarea-edit {
    width: 100%;
    color: #fff;
    border-color: #3D3F43;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 70px 12px 14px;
    font-family: $font;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 19.5px */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__create-button {
    width: 38px;
    height: 38px;
    flex: 0 0 auto;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}