.timeline-line {
  display: grid;
  grid-auto-flow: column;
  position: relative;
  min-height: 40px;
  box-shadow: inset 0 -1px 0 #2B2C2F;

  &__legend {
    position: sticky;
    left: 0;
    width: 240px;
    min-width: 240px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    box-shadow: inset -1px -1px 0 #2B2C2F;
    background-color: #191B1E;
    z-index: 120;
  }

}