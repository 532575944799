body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.modal-opened {
    overflow: hidden;
  }
}

input, button, form {
  font-family: $font;
}

button {
  cursor: pointer;
}
