
.pipeline-form {
  &__head {
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__inner {
    padding: 0 41px;
    color: #fff;
  }

  &__back {
    opacity: 0.5;
    margin-right: 10px;
    font-size: 30px;
  }

  &__actions {
    margin-top: 20px;
  }

  &__name {
    margin-bottom: 20px;
  }

  &__name-input {
    padding: 5px;
    width: 100%;
  }

  &__preview {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
