.task {
  position: relative;
  margin-bottom: 8px;
  z-index: 1;
  cursor: pointer;
  max-width: 274px;

  &__project {
    display: inline-block;
    font-size: 13px;
    color: #9499A4;
    border-radius: 100px;
    background-color: #F1F3F8;
    font-weight: 500;
    padding: 2px 5px;
    margin-bottom: 5px;
  }

  &__kanban-options {
    position: absolute;
    top: 6px;
    right: 6px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    display: flex;
    z-index: 10;
  }

  &__executors-select {
  }

  &:hover &__kanban-options{
    opacity: 1;
    pointer-events: auto;
  }

  &__kanban-options-item {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    border: 1px solid #EAECF2;
    background: #ffffff;
    box-shadow: 0 8px 10px 0 rgba(44,67,101,0.10);
    transition: all 0.3s;
    border-right: none;

    &:last-child {
      border-right: 1px solid #EAECF2;
    }

    img {
      transition: all 0.3s;
    }

    &:hover {
      background: #FF234C;
    }

    &:hover img {
      filter: brightness(0) invert(1);
    }
  }

  &__kanban-options-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__main {
    background-color: #ffffff;
    padding: 6px 7px 4px;
    position: relative;

    &_status {
      &_10 {
        background-color: #ffffff;
      }

      &_20, &_30, &_40, &_50, &_60, &_70 {
        background-color: #d9ffcb;
      }

      &_80 {
        background-color: #bcbcbc;
      }
    }
  }

  &__status {
    display: inline-block;
    font-size: 13px;
    color: #9499A4;
    border-radius: 100px;
    background-color: #F1F3F8;
    font-weight: 500;
    padding: 2px 5px;
    margin-bottom: 5px;


  }

  &_no-estimate {
    .task {
      &__main {
        background-color: #ffdfe5;
      }

      &__project {
        background-color: #ffcad4;
        color: #bb4e63;
      }
    }
  }

  &_performer {
    .task {
      &__main {
        background-color: #c6e4c8;
      }
    }
  }

  &_archive {
    .task {
      &__main {
        background-color: #eff79c;
      }
    }
  }

  &_has-subtasks {
    opacity: 0.5;
  }

  &__inner {

  }

  &:hover {
    .task__statuses-pencil {
      opacity: 1;
    }
  }

  &__statuses {
    display: flex;
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
  }

  &__statuses-indicator {
    width: 4px;
    flex: 1 1 auto;
    height: 100%;
  }

  &__statuses-indicator3 {
    background-color: $color-alert;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
  }


  &__statuses-indicator2 {
    background-color: $color-orange;
    width: 4px;
    height: 100%;
    bottom: 0;
    left: 0;
  }


  &__statuses-pencil {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    background-color: $color-gray-pencil;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity $default-transition-fast;
  }

  &__statuses-icon {

  }

  &__description {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    display: block;
    white-space: pre-wrap;
  }

  &__spent {
    margin-bottom: 8px;
    font-size: 14px;
  }

  &__bottom {
    img {
      display: block;
    }
  }

  &__datetime {
    font-family: $font;
    font-size: 14px;
    color: $color-gray-bar;

    &.alert {
      color: #FF234C;
    }

    &.pause {
      color: #58a415;
    }

    &.support {
      color: #377DFF;
    }
  }

  &__icon-alert, &__icon-support {
    margin-right: 5px;
    position: relative;
  }

  &__messang {
    display: flex;
    margin-right: 8px;
    align-items: center;

  }

  &__messang-icon {
    display: block;

    img {
      width: 12px;
    }
  }

  &__messang-number {
    font-family: $font;
    font-size: 12px;
    color: $color-gray-number;
    padding-left: 5px;
  }

  &__notes {
    display: flex;
    align-items: center;
  }

  &__notes-icon {
    img {
      margin-right: 2px;
      width: 13px;
    }
  }

  &__notes-number {
    font-family: $font;
    font-size: 12px;
    color: $color-gray-number;
    padding-left: 4px;
  }

  &__container {

  }
  &__wrapper-datetime {
    display: flex;
    align-items: center;
    margin-right: 8px;

    svg, img {
      width: 14px;
      height: 14px;
      display: block;
    }

    &.alert {

      .task__icon-alert {
        display: block;
      }

      .task__datetime {
        color: $color-alert;
      }
    }

    &.fire {

      .task__icon-fire {
        display: block;
      }

      .task__datetime {
        color: $color-orange;
      }
    }

    &.ok {

      .task__icon-ok {
        display: block;
      }

      .task__datetime {
        color: $color-green;
      }
    }

  }
  &__wrapper-user {
    display: flex;
  }

  &__link-user {
    margin-right: 6px;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    overflow: hidden;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
      image: url("/static/images/temp/user1.jpg");
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -2px;
  }

  &__wrapper-left {
    display: flex;
  }

  &__wrapper-user {
    margin-left: 10px;
    display: inline-block;
  }

  &__wrapper-user-line {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }

  &_image {
    margin: 0 -16px 8px;
    display: block;
    height: 118px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
      image: url("/static/images/temp/image1.jpg");
    }
  }

  &__edit {
    textarea {
      resize: none;
      height: 100%;
    }
  }
}