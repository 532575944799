.index-tasklist {

  &__head {
    display: flex;
    padding: 18px 40px;
    align-items: center;
  }

  &__button {
    margin-right: 45px;

  }

  &__button-text {
    position: relative;
    top: 2px;
    left: 1px;

  }

  &__search {

  }

  &__search-icon {

  }

  &__input {
    border: none;
    width: 200px;
    height: 38px;
    background: {
      image: url('../svg/search.svg');
      position: 14px center;
      repeat: no-repeat;
      size: 15px;
    };

    //border: 1px solid #EAECF2;
    //box-shadow: 0 10px 7px 0 rgba(224,230,240,0.20);
    font-size: 15px;
    color: $color-gray-light;
  }

  &__filters {
    display: flex;
    padding: 26px 40px 30px;
    margin: 0 -8px;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 8px;
  }

  &__title {
    color: $color-gray-light;
  }

  &__select {

  }

  &__option {

  }

  &__option-photo {

  }

  &__option-name {

  }

  &__check-icon {

  }

  &__status {

  }

  &__task {
    color: $color-gray-light;

  }

  &__circle {
    &_blue {

    }

    &_green {

    }

    &_grey {

    }

    &_yellow {

    }
  }

  &__task-option {

  }

  &__list {

  }

  &__items {

  }

  &__item {

  }

  &__item-head {

  }

  &__item-id {

  }

  &__item-title {

  }

  &__item-info {

  }

  &__item-status {

  }

  &__item-do {

  }

  &__item-do-icon {

  }

  &__item-do-label {

  }

  &__item-message {

  }

  &__item-message-icon {

  }

  &__item-message-read {

  }

  &__item-message-unread {

  }

  &__item-attach {

  }

  &__item-attach-icon {

  }

  &__item-attach-value {

  }

  &__item-under {

  }

  &__item-company {

  }

  &__item-company-icon {

  }

  &__item-company-label {

  }

  &__item-workers {

  }

  &__item-worker {

  }

  &__item-worker-arrow {

  }
}