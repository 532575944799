.kanban {
  width: calc(100vw - 246px);
  flex: 1 1 auto;
  user-select: none;
  min-height: calc(100vw - 200px);
  overflow-x: scroll;

  padding-left: 41px;

  [data-simplebar] {
    height: 100%;
  }
  .simplebar-track.simplebar-horizontal {
    height: 17px;
  }
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    &::before {
      background: #ffffff;
    }
    height: 12px;
  }
  .simplebar
  .simplebar-scrollbar {
    height: 12px;
    &::before {
      background: #ffffff;
    }
  }
  &__inner {
    width: 2600px;
    height: 100%;
    min-height: calc(100vw - 200px);
  }
  //overflow-x: scroll;
  &__list {
    margin-bottom: 32px;
    height: 100%;
  }
}

.base-wrapper__main_wide {
  .kanban {
    width: 100vw;
  }
}