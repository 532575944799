.tasks-column {
  width: 100%;
  .simplebar-track {
    right: -10px;
  }
  .simplebar-scrollbar {
    width: 2px;
    &::before {
      background: rgba(255,255,255, 0.5);
    }
  }
  &__title {
    color: #ffffff;
    font-weight: 500;
    font-size: 17px;
  }

  &__menu {
    color: #ffffff;
    display: flex;
    padding: 3px;
  }

  &__list {
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    align-items: center;
  }

  &__dot {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background: $color-gray-border;
    overflow: hidden;
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__add-task {
    display: flex;
    padding: 6px 0 ;
    align-items: center;
    justify-content: center;
  }

  &__save-task {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__save-task-button {
    width: 38px;
    height: 38px;
    margin-left: 8px;
    flex: 0 0 auto;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  &__save-task-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    margin-left: 8px;
    flex: 0 0 auto;
  }

  &__list-plus {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  &__add {
    color: rgba(255,255,255,0.53);
  }

  &__simplebar {
    height: auto;
    max-height: calc(100vh - 229px);
    width: 100%;

    .simplebar-track {
      right: -14px;
    }

    &_nofoot {
      max-height: calc(100vh - 262px);
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__create-form {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 10px;
    background-color: #191B1E;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      content: '';
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.78);
    }

    textarea {
      font-family: 'Roboto';
      width: 100%;
      font-size: 15px;
      line-height: 1.3em;
      border: none;
    }
  }

  &__create-area-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-color: #fff;
  }

  &_adding {
    .tasks-column {
      &__list {
        min-height: 86px;
      }

      &__simplebar {
        max-height: calc(100vh - 284px);
      }
    }
  }

  &__create-area-options {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin-top: 20px;
  }

  &__create-area-options-left {
    display: flex;
  }

  &__create-area-options-right {
    display: flex;
    align-items: flex-end;
  }

  &__create-area-option {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid #DEE0E7;
    background: #ffffff;
    transition: all 0.3s;

    img {
      transition: all 0.3s;
    }

    &:hover {
      background: #F1F3F8;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__create-area-option-icon {
    display: flex;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__create-area-date {
    display: flex;
    width: max-content;

    &._active {
      .mx-datepicker {
        width: 139px;

        input {
          padding: 6px 30px 6px 10px;
        }
      }
    }

    .mx-datepicker {
      width: 30px;
      height: 30px;

      input {
        padding: 0;
        height: 30px;
        border: 1px solid #DEE0E7;
        border-radius: 0;
        transition: background-color .3s;
        box-shadow: none;
      }

      &:hover {
        input {
          background: #F1F3F8;
        }
      }
    }
  }

  &__project-wrap {
    flex: 0 1 100%;
  }

  &__project {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    padding: 10px 14px 16px;
  }

  &__project-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  &__project-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
  }

  &__create-form-name {
    &::-webkit-input-placeholder {
      color: $color-black;
    }
  }
}