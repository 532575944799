.button {
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
  padding: 5px 16px;
  color: $color-alert;
  letter-spacing: 0;
  line-height: 18/15*1em;
  text-align: center;
  transition: background-color $default-transition, color $default-transition, border-color $default-transition;
  border: 1px solid rgba(255, 35, 76, 0.16);
  font-weight: 400;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    border-color: rgba(255, 35, 76, 0.5);
  }


  &_default {
    padding: 6px 16px;

    &:hover {
      border: 1px solid $color-dark-blue;
      background-color: $color-dark-blue;
    }
  }

  &_alert {
    background-color: $color-alert;
    color: #ffffff;
  }

  &_save {
    color: white;
    border: 1px solid $primary-color;
    background: {
      color: $primary-color;
      image: url('../svg/save-check.svg');
      position: 15px calc(50% - 2px);
      repeat: no-repeat;
    }

    &:hover {
      border: 1px solid $color-dark-blue;
      background-color: $color-dark-blue;
    }
  }

  &_cancel {
    border: 1px solid rgba(55, 125, 255, .25);
    padding: 9px 14px 11px 34px;
    color: $primary-color;
    background: {
      color: $t;
      image: url('../svg/button-arrow-blue.svg');
      position: 14px calc(50% - 1px);
      repeat: no-repeat;
    }

    &:hover {
      border: 1px solid rgba(55, 125, 255, .55);
    }
  }

  &_edit-blue {
    background-color: $t;
    background-repeat: no-repeat;
    border: 1px solid rgba(55, 125, 255, .25);
    color: $primary-color;
    background: {
      color: $t;
      image: url('../svg/pencil-blue.svg');
      position: 14px calc(50% - 1px);
      repeat: no-repeat;
    }

    &:hover {
      border: 1px solid rgba(55, 125, 255, .55);
    }
  }

  &_edit-white {
    border: 1px solid rgba(255, 255, 255, .25);
    color: #fff;
    background: {
      color: $t;
      image: url('../svg/pencil-white.svg');
      position: 14px calc(50% - 1px);
      repeat: no-repeat;
    }

    &:hover {
      border: 1px solid rgba(255, 255, 255, .55);
    }
  }

  &_close {
    color: $color-gray;
    border: 1px solid rgba(106, 117, 142, .25);
    background: {
      color: $t;
      image: url('../svg/button-close.svg');
      position: 16px calc(50% - 1px);
      repeat: no-repeat;
    }

    &:hover {
      border: 1px solid rgba(106, 117, 142, .55);
    }
  }

  &_transparent {
    background: transparent;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  &_wide {
    width: 100%;
  }

  &_big {
    padding: 12px 19px;
  }
}

