
.color-circle {
  &__link {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__selected {
    position: relative;
    top: -1px;
  }
}
