.link {
  font-size: 15px;
  color: $primary-color;
  letter-spacing: 0;
  line-height: 1;
  border-bottom: 1px solid $t;
  transition: border-bottom-color $default-transition;

  &:hover {
    border-bottom: 1px solid rgba(55,125,255,0.35);
  }

  &_gray {
    font-size: 13px;
    color: #8D98B2;

    &:hover {
      border-bottom: 1px solid #CCD2DF;
    }
  }
}