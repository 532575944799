
.search {
    position: relative;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 20px;
    input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #EAECF2;
        padding: 10px 27px;
        color: #6C707B;
        font-size: 15px;
        &.transparent {
            border-color: rgba(255, 255, 255, 0.16);
            background: transparent;
            color: #ffffff;
        }
    }
    &__icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
    }
}
