.project-head {
  display: flex;
  padding: 32px 40px;
  align-items: center;

  &__title {
    color: #ffffff;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &__title-label {
    font-size: 28px;
    margin-right: 13px;
  }

  &__title-icon {

  }

  &__search {

  }

  &__input {
    background: {
      color: transparent;
      image: url("../svg/search_white.svg");
      repeat: no-repeat;
      position: 0px center;
    };
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    text-align: center;
    font-size: 15px;
    height: 35px;
    margin-right: 72px;
    color: #ffffff;
  }

  &__sort {

  }

  &__sort-icon {
    margin: 0px 0px 0px 11px;

  }
}