
.pipeline-item {
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  display: grid;
  grid-template-columns: 1fr 120px 120px;

  &__head {
    display: grid;
    align-items: center;
    grid-template-columns: 55px 1fr 200px;
  }

  &__textarea {
    font-family: inherit;
    border: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;
    padding: 10px 0 10px 0;
    height: 36px;
    min-height: 36px;
    border-right: 1px solid #e1e1e1;
    resize: vertical;
  }

  &__num {
    font-size: 14px;
    padding-left: 8px;
    color: #ababab;
  }

  &__performer {
    height: 36px;
    border: none;
  }

  &__options {
    padding-left: 10px;
  }

  &__actions {
    border-left: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__next {
    border-left: 1px solid #e1e1e1;
  }

  &__next-item {
    background-color: #FF234C;
    color: #fff;
    padding: 0 4px;
    border-radius: 10px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &__next-items {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 4px;
  }

  &__next-input {
    width: 40px;
    padding: 0;
    border: none;
    background-color: transparent;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &__action {
    display: block;
    padding: 5px 9px;
    border: none;
    color: #fff;
    border-radius: 25px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__add {
    background-color: #30851f;
  }

  &__delete {
    background-color: #ac111e;
  }
}
