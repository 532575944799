
.task {
  &__form {
    textarea {
      padding: 0;
      font-family: 'Roboto';
      resize: none;
      width: 100%;
      font-size: 15px;
      border: none;
    }
  }

  &__multi-icon {
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 1;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__main {
    //margin-bottom: 7px;
  }

  &__tags-list {
    display: flex;
    gap: 5px;
  }

  &__tags-list-item {
    flex: 1 0 auto;
    position: relative;
    margin-bottom: 4px;

    &:before {
      position: absolute;
      top: -10px;
      height: 15px;
      content: '';
      width: 100%;
      left: 0;
      right: 0;
    }

    &:hover .task__tags-list-label {
      opacity: 1;
    }
  }

  &__tags-list-badge {
    height: 4px;
    width: 100%;
    border-radius: 4px;
  }

  &__tags-list-label {
    background-color: #383737;
    color: #fff;
    font-size: 12px;
    padding: 6px 15px;
    position: absolute;
    pointer-events: none;
    border-radius: 2px;
    bottom: 5px;
    opacity: 0;
    transition: 0.3s all;
  }

  &__edit {
    textarea {
      padding: 0;
      font-family: 'Roboto';
      width: 100%;
      height: 15px;
      font-size: 15px;
      line-height: 1.3em;
      border: none;
      margin-bottom: 2px;
    }
  }

  &__saver {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
