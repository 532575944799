.loading-group {
  &__name {
    display: flex;
    align-items: center;
    padding: 20px 24px;
  }

  &__name-text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.32);
    letter-spacing: 0.75px;
    text-transform: uppercase;
    padding-right: 8px;
    font-weight: 500;
  }

  &__name-icon {
    img {
      display: block;
    }
  }
}