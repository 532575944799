.column-list {
  display: flex;
  align-items: flex-start;
  height: 100%;

  &__item {
    padding: 6px 8px 5px;
    background: $color-primary;
    display: flex;
    margin-right: 5px;
    flex: 0 0 280px;

    &:last-child {
      margin-right: 0;
    }
  }
}