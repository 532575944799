
.task-options-tags {
  padding-bottom: 20px;
  position: absolute;
  min-height: 200px;
  width: 100%;
  background-color: #ffffff;
  z-index: 3;
  box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);

  &__title {
    color: rgba(12, 34, 68, 0.32);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.07em;
    font-weight: 500;
  }

  &__top {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    display: block;
    padding: 13px;
    margin-right: -13px;
  }

  &__search {
    margin-bottom: 15px;
  }

  &__list {
    margin-bottom: 15px;
  }

  &__button {
    padding: 0 24px;
  }
}
