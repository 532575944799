.select-item {
  &__link {
    display: flex;
    padding: 6px 22px;
    align-items: center;
    &:hover {
      background: #F1F3F8;
    }
  }
  &__avatar {
    margin-right: 10px;
  }
  &__name {
    color: $color-gray-word3;
    flex: 1 1 auto;
    font-size: 15px;
  }
}