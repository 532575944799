.project-subhead {
  display: flex;
  align-items: center;
  padding: 0 41px;
  margin-bottom: 32px;

  &__info-tab {
    flex-grow: 1;
  }

  &__info-tab-item {
    color: #ffffff;
    padding: 12px 21px;
    font-size: 15px;
    display: inline-block;
    border : 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  &__info-tab-item.router-link-active {
    border-radius: 30px;
    background-color: $color-alert;
    border-color: $color-alert;
  }

  &__filters {
    display: flex;
    align-items: center;

  }

  &__tags {
    margin-right: 57px;
  }

  &__tags-select {
    background-color: transparent;
    color: #ffffff;
    font-size: 15px;
    border: none;
    width: 70px;
  }

  &__circles {

  }

  &__circle {

  }

  &__deadlines {
    margin-right: 57px;
  }

  &__deadlines-select {
    background-color: transparent;
    color: #ffffff;
    font-size: 15px;
    border: none;
    width: 260px;
  }

  &__participants {

  }

  &__participants-select {
    background-color: transparent;
    color: #ffffff;
    font-size: 15px;
    border: none;
    width: 100px;
  }

  &__avatars-list {

  }

  &__avatars-item {

  }
}

