.images-list {
  &__item {

  }

  &__item:nth-child(2) {
    .list-image {
      background-image: url("/static/images/temp/tires.jpg");
    }

  }

  &__item:nth-child(4) {
    .list-image {
      background-image: url("/static/images/temp/dog.jpg");
    }

  }

  &__item:nth-child(5) {
    .list-image {
      background-image: url("/static/images/temp/rabbit.jpg");
    }

  }

}