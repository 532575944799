
.tags-add {
  position: absolute;
  width: 100%;
  min-height: 268px;
  background: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
  display: flex;
  flex-direction: column;
  z-index: 3;
  &__top {
    padding: 0 24px;
    display: flex;
    align-items: center;
  }
  &__back-icon {
    padding: 12px;
    margin-left: -12px;
    margin-right: 2px;
  }
  &__title {
    font-size: 12px;
    color: rgba(12, 34, 68, 0.32);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.07em;
    flex-grow: 1;
  }
  &__close-icon {
    padding: 12px;
    margin-right: -12px;
  }
  &__input {
    padding: 0 24px;
    margin-bottom: 15px;
  }
  &__color-list {
    padding: 0 24px;
    flex: 1 1 auto;
  }
  &__bottom {
    padding: 0 24px 15px 24px;
    display: flex;
    justify-content: space-between;
  }
  &__button {
    width: 106px;
  }
  
}
