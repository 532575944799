.statuses-list {
  padding-left: 34px;
  &__item {

  }

  &__status {

  }

  &__item {
    .status__icon {
      background-color: #000000;
    }
    .status__name{
      color: #000000;
    }
  }

}