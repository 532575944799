
.task-options-workers-search {
  width: 100%;
  padding: 0 24px;

  &__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #EAECF2;
    padding: 10px 27px;
    color: #6C707B;
    font-size: 15px;
  }
}
