
.multiselect {
  &__element {
    font-size: 15px;
  }
  
  &__single {
    font-size: 15px;
  }
}

.task-options-status {
  padding-bottom: 20px;
  position: absolute;
  min-height: 103px;
  width: 100%;
  background-color: #ffffff;
  z-index: 3;
  box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
  
  &__title {
    color: rgba(12, 34, 68, 0.32);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.07em;
    font-weight: 500;
  }
  
  &__top {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__icon {
    display: block;
    padding: 13px;
    margin-right: -13px;
  }
  
  &__form {
    padding: 0 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  &__select {
    position: relative;
    top: 0.166em;
    width: 130px;
    .multiselect {
      width: 150px;
      &__content-wrapper {
        height: 350px;
      }
    }
  }
  
  &__label {
    color: #A9AEB9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    strong {
      color: #3A3E44;
    }
  }
  &__button {
    padding: 0 24px;
  }

  &__parent {
    margin-top: 20px;
  }
}
