
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
}

.loading-icon {
  display: block;
  padding: 7px;
  width: 10px;
  height: 10px;
  border: 3px solid rgba(255, 35, 76, 0.4);
  border-right-color: rgba(255, 35, 76, 0.9);
  border-radius: 50%;
  -webkit-animation: rotate .4s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
}

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise.
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
