
.list-image {
  position: relative;
  &__icon {
    position: absolute;
    top: -5px;
    right: -5px;
    opacity: 0;
    transition: .1s ease-in-out;
    padding: 5px;
  }
  &:hover {
    .list-image__icon {
      opacity: 1;
    }
  }
}

