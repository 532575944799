.select {
  padding-bottom: 20px;
  background: #ffffff;
  z-index: 10000;

  &__top {
    display: flex;
    padding: 0 22px;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(12, 34, 68, 0.32);
    font-weight: 500;
    letter-spacing: 0.07em;
  }

  &__closer {
    display: inline-block;
    padding: 12px;
    margin-right: -12px;
  }

  [data-simplebar] {
    height: 200px;
  }
}