.user-options-item {
  display: flex;
  align-items: center;
  padding: 13px 34px;
  background: transparent;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  color: #ffffff;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all .2s ease-in-out;
  width: 100%;
  cursor: pointer;

  &__icon {
    display: inline-block;
    margin-right: 11px;
  }

  &__check-label {
    padding-left: 11px;
  }

}