.task-index {
  width: 100vw;

  &__inner {
    position: relative;
    display: flex;
    margin: 50px auto;
    background-color: #ffffff;
    width: 902px;
    padding-left: 35px;
    z-index: 1;

  }

  &__overlay {
    position: relative;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
  }

  &__label {
    font-weight: 500;
    font-size: 28px;
  }

  &__statuses {
    display: flex;
    padding-top: 13px;
  }

  &__head {
    padding-top: 32px;
    margin-bottom: 10px;
  }

  &__top {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__parent, &__proj {
    font-size: 13px;
    color: #6C707B;
  }

  &__parent + &__proj {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #e1e1e1;
  }

  &__parent-label {

  }

  &__parent-link, &__proj-link {
    color: $color-alert;
  }

  &__tabs {
    border-bottom: 1px solid $color-border4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 10px;
  }

  &__tab-link {
    display: block;
    padding: 10px 0;
    color: $color-gray-word2;
    position: relative;
    transition: all 0.3s;

    &:after {
      content: '';
      left: 0;
      right: 0;
      position: absolute;
      bottom: -1px;
      background: $color-alert;
      height: 3px;
      transition: all 0.3s;
      opacity: 0;
    }

    &:hover {
      color: $color-black;
    }

    &._active {
      color: $color-black;

      &:after {
        opacity: 1;
      }
    }
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.7);

    display: grid;
    grid-template-columns: 1fr;
  }

  &__overlay,
  &__inner {
    grid-column: 1;
    grid-row: 1;
  }

  &__main {
    padding-right: 50px;
    flex-grow: 1;
    max-width: 590px;
  }

  &__position {

  }

  &__position-name {
    color: $color-gray-word2;

  }

  &__position-backlog {
    color: red;
    margin-left: 5px;
  }

  &__statuses-list {
    display: flex;
  }

  &__subhead {
    display: flex;
  }

  &__subhead-list {
    display: flex;
  }

  &__worker-icon {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    overflow: hidden;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
      image: url("/static/images/temp/user1.jpg");
    }

  }

  &__additional {
    padding: 23px 30px;

    &_first {
      padding-bottom: 0;
    }

    &_last {
      padding-top: 0;
    }
  }

  &__worker-list {
    background-color: $t;
    border: none;
  }

  &__additional-item {
    margin-bottom: 10px;
    display: block;
    text-decoration: none;

    &_editable {
      cursor: pointer;
    }
  }

  &__additional-label {
    font-size: 15px;
    color: #A9AEB9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
  }

  &__additional-value {
    font-size: 15px;
    color: #6C707B;
  }

  &__description {
    padding-top: 52px;
  }

  &__description-head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__description-label {
    font-weight: 500;
    font-size: 20px;
    padding-right: 12px;

  }

  &__description-link {

  }

  &__description-text {
    color: $color-gray-word;
    font-size: 15px;
    line-height: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    b {
      font-weight: 700;
    }

    .page {
      display: flex;
    }

    ol {
      padding-left: 18px;
    }

    ul {
      padding-left: 36px;
    }
  }

  &__editable {
    line-height: 1.6em;
    .editr--content {
      line-height: 1.6em;
    }
  }

  &__attachments {
    margin-top: 52px;
  }

  &__attachments-head {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }

  &__attachments-label {
    font-weight: 500;
    font-size: 20px;
    padding-right: 12px;
  }

  &__attachments-link {
    display: flex;
    border: 1px solid $color-border-pink;
    color: $color-alert;
    border-radius: 40px;
    width: 103px;
    height: 30px;
    align-items: center;
    justify-content: center;
  }

  &__attachments-image {

  }

  &__attachments-block {

  }

  &__attachments-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 539px;
  }

  &__supplements-title {
    font-weight: 400;
    font-size: 13px;
    color: $color-gray-word2;
    margin-top: 6px;
  }

  &__documents {
    display: flex;
    margin-left: -8px;
  }

  &__files-list {
    margin-top: 34px;
  }

  &__link-dowload-all {
    color: $color-border4;
  }

  &__comments {
    margin-top: 55px;
  }

  &__comments-label {
    display: flex;
    align-content: center;
  }

  &__comments-name {
    margin-right: 8px;
    margin-bottom: 28px;
    font-size: 20px;
    font-weight: 500;
  }

  &__comments-number {
    color: #C1C4D3;
    font-weight: 500;
    font-size: 15px;
    position: relative;
    top: 4px;
  }

  &__comments-list {

  }

  &__comments-user {
    display: flex;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__comments-avatar {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__comments-head {
    margin-left: 16px;
  }

  &__comments-worker {
    color: $color-primary;
    font-weight: 500;
    font-size: 15px;
    margin-right: 10px;

  }

  &__comments-date {
    color: #A9AEB9;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__comments-time {
    color: $color-border4;

  }

  &__comments-do {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__comments-icon {
    margin-right: 10px;
  }

  &__comments-delete {
  }

  &__comments-subhead {
  }

  &__comments-text {
    margin-top: 10px;
    font-size: 15px;
    color: $color-gray-word;
    line-height: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;

    a {
      color: $color-alert;
    }
  }

  &__comments-answer {
    color: $color-alert;
    margin-top: 10px;
    margin-bottom: 45px;
    display: none;
  }

  &__comments-edit-button {
    display: inline-block;
    margin-right: 11px;
  }

  &__comments-add-file {

  }

  &__do-comments {
    display: flex;
    padding-bottom: 52px;
    padding-top: 50px;
  }

  &__do-comments-avatar {
    margin-right: 15px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
      image: url("/static/images/temp/user1.jpg");
    }
  }
  &__cooments-form {

  }

  &__do-comments-input {
    padding: 0 40px 0 12px;
    width: 426px;
    height: 45px;
    margin-right: 20px;
    font-size: 15px;
    color: #6C707B;
    letter-spacing: 0;
    border: none;
    border: 1px solid #DDE1EA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font;
    line-height: 1.6em;
    resize: none;
    &_edit {
      padding: 10px !important;
      margin-top: 10px;
    }
  }

  &__do-comments-icon {
    position: relative;
    top: 10px;
  }

  &__right {
    min-width: 278px;
    border: 1px solid $color-border4;
    background-color: #ffffff;
  }


  &__tags {
    border-bottom: 1px solid $color-border4;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
  }

  &__tag {
    color: #fff;
    padding: 1px 8px 2px;
    border-radius: 10px;
  }

  &__deadline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    background-color: $color-gray-pencil;
    gap: 10px;

    .mx-datepicker {
      width: 115px;
    }

    .mx-input {
      padding-left: 7px;
      padding-right: 20px;
    }
  }

  &__deadline-label {
    text-align: center;
    display: block;
    color: rgba(12, 34, 68, 0.32);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 11px;
    letter-spacing: 0.07em;
  }

  &__deadline-checkbox {

  }

  &__deadline-date {
    color: $color-gray-word;
    font-weight: 500;
  }

  &__start-at {

  }

  &__options {

  }

  &__options-list {
  }

  &__time {
    margin-top: 20px;
  }

  &__support {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 22px;
    border-bottom: 1px solid #EAECF2;
  }

  &__support-checkbox {
    margin: 0;
  }

  &__support-label {
    padding-left: 18px;
    color: #3A3E44;
    height: 50px;
    display: flex;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__spent {
    padding: 0 30px;
    color: #3A3E44;
    opacity: .3;
    font-size: 12px;
  }

  &__attachments-drag-n-drop-label {
    position: relative;
    width: 100%;
    height: 80px;
    border: 1px solid #DDE1EA;
    padding: 15px;
    font-size: 15px;
    color: #6C707B;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font;
    line-height: 1.6em;
  }

  &__attachments-drag-n-drop-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}