.tabs {
  display: flex;
  align-items: center;

  &__info-tab-item {
    color: #ffffff;
    padding: 9px 19px;
    font-size: 15px;
    display: inline-block;
    border : 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    transition: border-color .3s ease-in-out;
    margin-right: 8px;

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  &__info-tab-item.router-link-active {
    border-radius: 30px;
    background-color: $color-alert;
    border-color: $color-alert;
  }
}