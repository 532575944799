.timeline-days {
  display: flex;

  &__hour {
    flex: 0 0 var(--timeline-hour);
    width: var(--timeline-hour);
    height: var(--timeline-line-height);
  }

  &__day {
    box-shadow: inset -1px 0 0 #2B2C2F;
  }

  &__hours {
    display: grid;
    grid-auto-flow: column;
  }

  &__hour {
    box-shadow: inset -1px 0 0 #222225;

    &:last-child {
      box-shadow: none;
    }
  }
}