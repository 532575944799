.list-image  {
  display: flex;
  width: 120px;
  height: 122px;
  justify-content: space-between;
  margin-right: 13px;
  margin-bottom: 13px;
  border: 1px solid $color-border4;
  background: {
    size: cover;
    position: center;
  };
}