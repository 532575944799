.kanban-layout {
  position: relative;
  height: 100vh;
  padding: 32px 0;

  &__head {
    display: flex;
    justify-content: space-between;
    padding: 0 41px;
  }

  &__title {
    color: #ffffff;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
  }

  &__opener {
    padding: 10px;
  }

  &__subhead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: 0 41px;
  }
}