
.subtask {
  display: grid;
  grid-template-columns: 1fr 70px;

  &__performer {
    display: flex;
    justify-content: flex-end;
  }

  &__performer-select {
    cursor: pointer;
  }

  &__status {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 5px;
    background: #F1F3F8;
    border-radius: 9px;
    color: #9499A4;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  &__name {
    display: block;
    margin-top: 6px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__performer {
    position: relative;
  }

  &__performer-selector {
    position: absolute;
    top: 0;
    right: 0;
    width: 270px;
    background: #FFFFFF;
    border: 1px solid #EAECF2;
    box-shadow: 0px 10px 20px rgba(44, 67, 101, 0.100524);
    z-index: 1000;
  }
}
