.input {
  border: 1px solid #c1c1c1;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  width: 100%;
  display: block;
  height: $default-input-height;
  font-family: $font;
}