.comments-list {
  &__item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__comment-item {

  }
}