.task-time {
  background-color: #F1F3F8;
  padding: 12px 14px;

  &__estimated {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__estimated-label {
    flex: 0 0 160px;
    text-align: left;
    text-transform: uppercase;
    color: rgba(12, 34, 68, 0.32);
    letter-spacing: 0.75px;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 0 7px 25px;

    background: {
      image: url("../svg/times.svg");
      position: 0 50%;
      repeat: no-repeat;
    };
  }

  &__estimated-inputs {
    display: flex;
    margin: 0 -2px;
  }

  &__estimated-input {
    padding: 0 2px;
    position: relative;
    width: 100%;
  }

  &__estimated-input-label {
    position: absolute;
    top: 50%;
    transform: translate3d(0,-50%,0);
    padding-left: 10px;
    color: #A9AEB9;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__estimated-input-value {
    border: 1px solid #DDE1EA;
    font-size: 15px;
    color: #000;
    height: 36px;
    max-width: 108px;
    appearance: none;

    &_days {
      padding-left: 55px;
      padding-right: 0;
    }

    &_hours {
      padding-left: 63px;
      padding-right: 0;
    }

    &_min {
      padding-left: 50px;
      padding-right: 0;
    }
  }
}