.notifications {
  margin-left: 240px;
}

.vue-notification {
  background: $color-alert !important;
  border-left: none !important;
  display: flex !important;
  align-items: center;
  padding: 21px 41px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.notification-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-right: 42px !important;
}

.notification-content {
  font-size: 14px;
}

