
.base-wrapper {
  min-width: 100%;
  background: {
    repeat: no-repeat;
    size: cover;
  };

  &__inner {
    display: flex;
  }

  &__main {
    width: calc(100vw - 280px);
    &_wide {
      width: 100vw;
    }
  }


}
