
.task-index{
  &__do-comments-input {
    padding: 10px 20px 15px 14px;
    line-height: 1.6em;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
}
