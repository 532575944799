$max-row-width: 1008;
$grid-columns: 12;
$grid: (
        small: (
                from: 0,
                to: 977,
                gutter: 12,
                spacer: 8
        ),
        large: (
                from: 978,
                gutter: 30
        )
);

$body-font-size: 16px;
$font: 'Roboto', sans-serif;
$h-font: 'Roboto', sans-serif;

$t: transparent;

$color-blue: #377DFF;
$color-dark-blue: #2965D3;
$color-gray: #6A758E;
$color-gray-light: #8D98B2;
$color-gray-bg: #F6F7FD;
$color-gray-ff: #E4E8F4;
$color-gray-hover: #EBEEF7;
$color-gray-border: #979797;
$color-gray-border2: #EAECF2;
$color-gray-border3: #979797;
$color-border4: #EAECF2;
$color-border-pink: #F39EAE;
$color-black: #2B333F;
$color-primary: #191B1E;
$color-gray-name: rgba(255,255,255,0.32);
$color-gray-pencil: #F1F3F8;
$color-gray-bar: #9499A4;
$color-gray-number: #6D7581;
$color-gray-word: #6C707B;
$color-gray-word2: #A9AEB9;
$color-gray-word3: #3A3E44;

$primary-color: #377DFF;
$color-alert: #FF234C;
$color-orange: #FF742E;
$color-green: #2AC77D;

$default-input-height: 37px;
$default-transition: 0.2s ease-in-out;
$default-transition-fast: 0.2s ease-in-out;
