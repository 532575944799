.timeline-task {
  top: 4px;
  bottom: 4px;
  background-color: $color-alert;
  z-index: 10;
  box-shadow: inset -2px 0 0 #191B1E;
  border-radius: 2px;
  display: block;
  transition: 0.3s;

  &__verbose {
    position: absolute;
    bottom: 100%;
    background-color: #fff;
    border-radius: 2px;
    width: 300px;
    margin-bottom: 2px;
    padding: 6px 7px 4px;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    transform: translate3d(-45px,var(--timeline-line-height),0) scale(0.7);
  }

  &:hover {
    z-index: 1000;
    background-color: #ff8287;

    .timeline-task {
      &__verbose {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0,0,0) scale(1);
      }
    }
  }

  &__name {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
  }

  &__project {
    display: inline-block;
    font-size: 13px;
    color: #9499A4;
    border-radius: 100px;
    background-color: #F1F3F8;
    font-weight: 500;
    padding: 2px 5px;
    margin-bottom: 5px;
  }

  &__owner {
    font-size: 11px;
  }
}