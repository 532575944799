
.avatar {
  position: relative;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 19px;
  z-index: 10;
  cursor: default;
  overflow: hidden;

  /*
  transition: all 0.3s;

  &:hover {
    transform: scale(2.5);
    overflow: auto;
  }
  */

  &_small {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }

  &_large {
    width: 70px;
    height: 70px;
    font-size: 30px;
    font-weight: 700;
  }

  &__default {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    };
  }

  &__label {
    text-transform: uppercase;
    color: #ffffff;
  }

  &__tooltip {
    display: block;
    position: absolute;
    top: 120%;
    left: -50%;
    transform: translate(-25%);
    padding: 5px 10px;
    background: #000000;
    color: #ffffff;
    white-space: nowrap;
    z-index: 100000;
  }

  &__close-icon {
    padding: 4px;
    display: inline-block;
    margin-right: -4px;
  }
}

.tooltip {
  top: 5px;
  color: #ffffff;
  background: #000000;
  font-size: 15px;
  padding: 5px 9px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1000;

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
