
.pipelines-list {
  &__item {

  }

  &__pipeline {
    position: relative;
  }

  &__pipeline-name {
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
  }

  .pipeline-view {
    position: relative;
  }
}
