.log {
  --log-elements-padding: 24px;
  --log-item-border-color: #2B2C2F;

  &__date {
    display: flex;
    padding: 24px var(--log-elements-padding);
    border-bottom: 1px solid var(--log-item-border-color);
    position: relative;
    align-items: flex-start;

    &:last-child {
      border-bottom: none;
    }
  }

  &__date-name {
    flex: 0 0 180px;
    position: sticky;
    top: 0;

    &_narrow {
      flex: 0 0 100px;
      font-size: 14px;
    }
  }

  &__date-items {
    flex: 1 1 auto;
  }

  &__load-more-wrapper {
    padding: 24px var(--log-elements-padding);
  }

  &__load-more {
    width: 100%;
    color: #fff;
    background-color: $color-blue;
    border: none;
    display: block;
    padding: 10px 0;

    &:hover {
      background-color: #fff;
      color: $color-blue;
      border: none;
    }
  }

  &_light {
    --log-item-border-color: #EAECF2;
  }

  &_embedded {
    --log-elements-padding: 0;
  }
}