.project-subpage {
  overflow: scroll;
  height: calc(100vh - 175px);
  padding: 0 40px 50px;

  .project-subpage__container {
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 20px;
  }
}