.time-project {
  padding: 18px 24px;
  border-bottom: 1px solid #2B2C2F;

  &__name {
    color: #fff;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__container-right {
    display: flex;
    align-items: center;
  }

  &__download {
    margin-right: 20px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__workers {
    padding-top: 20px;
  }

  &__spent {
    color: #fff;
  }

  &__worker {
    padding-left: 40px;
    border-bottom: 1px solid #2B2C2F;

    &:last-child {
      border-bottom: none;

      .time-project {
        &__worker-container {
          border-bottom: none;
        }
      }
    }
  }

  &__worker-info {
    display: flex;
    align-items: center;
  }

  &__worker-name {
    color: #fff;
  }

  &__worker-spent {

  }

  &__worker-avatar {
    margin-right: 20px;
  }

  &__worker-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    cursor: pointer;
  }

  &__worker-tasks {
    padding-left: 20px;
    padding-bottom: 10px;
  }

  &__task {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.2s;

    &:hover {
      color: #fff;

    }
  }

  &__task-name {
    position: relative;

    &:before {
      content: '—';
      position: absolute;
      left: -15px;
      top: 0;
    }
  }

  &__task-spent {
    flex: 0 0 140px;
    text-align: right;
  }
}