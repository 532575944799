.user-options {
  &__button {
    padding: 0 33px;
    margin-bottom: 57px;
  }

  &__button-create-project {
    display: block;
  }

  &__info {
    text-align: center;
    margin-bottom: 50px;
  }

  &__avatar {
    display: inline-block;
    margin-bottom: 17px;
  }

  &__name {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__colorpicker-palette {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__colorpicker-button {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    width: 100%;
    background: transparent;
    color: #ffffff;
    padding: 16px 0;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}