.option {
  border-bottom: 1px solid $color-border4;
  height: 51px;
  color: #3A3E44;
  //transition: background-color .1s ease-in-out, border-color .1s ease-in-out;

  &__link {
    width: 279px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: #FF234C;
    color: #ffffff;
    border-color: #FF234C;
  }

  &:hover &__link svg path {
    fill: #ffffff;
  }

  &:hover &__link svg g {
    stroke: #ffffff;
  }

  &:hover &__link svg circle {
    stroke: #ffffff;
    fill: #ffffff;
  }

  &:hover &__link svg polygon {
    stroke: #ffffff;
  }

  &__link-icon {
    padding-left: 12px;
    width: 42px;
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
  }

  &__link-label {
    position: relative;
    padding-left: 10px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}