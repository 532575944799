.log-item {
  display: flex;
  margin-bottom: 24px;
  font-size: 15px;
  --log-item-main-color: #fff;
  --log-item-comment-bg-color: #24262A;

  &:last-child {
    margin-bottom: 0;
  }

  &__avatar {
    flex: 0 0 55px;
    width: 55px;
  }

  &__data {
    flex: 1 1 auto;
  }

  &__worker-name {
    color: var(--log-item-main-color);
    font-weight: 500;
  }

  &__entity {
    color: var(--log-item-main-color);

    &:hover {
      text-decoration: underline;
    }
  }

  &__bubble {
    margin-top: 8px;
    background-color: var(--log-item-comment-bg-color);
    font-size: 14px;
    color: var(--log-item-main-color);
    padding: 8px;
    display: inline-block;
    word-break: break-all;
  }

  &__time {
    margin-top: 2px;
  }

  &__from-to {
    white-space: nowrap;
    display: block;
    margin-top: 3px;
  }

  &__file {
    margin-top: 8px;
  }

  &_light {
    --log-item-main-color: #191B1E;
    --log-item-comment-bg-color: #dedede;
  }
}