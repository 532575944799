
.task-subtasks {
  margin-top: 52px;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__label {
    font-weight: 500;
    font-size: 20px;
    padding-right: 12px;
  }

  &__form {
    border: 1px solid #EAECF2;
    box-shadow: 0 10px 20px rgba(44, 67, 101, 0.100524);
    z-index: 100;
    margin: 0 0 20px;
  }

  &__textarea {
    border: none;
    max-width: 100%;
    min-width: 100%;
  }

  &__form-footer {
    border-top: 1px solid #EAECF2;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form-close {
    appearance: none;
    border: none;
    padding: 6px;
    display: block;
    background-color: transparent;
  }

  &__list {
    position: relative;
  }

  &__item-container {
    padding-left: 55px;

    &:after {
      position: absolute;
      width: 7px;
      height: 7px;
      left: 40px;
      top: 6px;
      background: #FFFFFF;
      border: 1px solid #EAECF2;
      content: '';
      border-radius: 10px;
      z-index: 1;
    }

    &:before {
      position: absolute;
      left: 0;
      width: 43px;
      height: 1px;
      top: 10px;
      background-color: #EAECF2;
      content: '';
    }

  }

  &__item {
    padding-bottom: 25px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: -10px;
      background-color: #EAECF2;
      width: 1px;
    }

    &:last-child {
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
}
