.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 382px;
    height: 306px;
    padding: 50px 76px;
  }

  &__title {
    align-self: center;
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__data {
    margin-bottom: 20px;

    input[type="text"], input[type="password"] {
      border: 1px solid #EAECF2;
      margin-bottom: 10px;
    }
  }

  &__buttons {
    align-self: center;
  }

  &__button {

  }

  &__error {
    color: #990055;
    font-size: 12px;
  }
}