
.pipeline-view-container {
  background-color: #191B1E;
  margin-bottom: 15px;
  padding: 20px;
  overflow: scroll;
}

.pipeline-view {
  &__task {
    flex: 0 0 270px;
    width: 270px;
    margin-right: 30px;
    position: relative;

    .task, .task__main {
      margin-bottom: 0;
    }

    &::after {
      display: block;
      position: absolute;
      width: 30px;
      height: 1px;
      content: '';
      right: -30px;
    }
  }

  &__layer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}

.arrow {
  pointer-events: none;
}

.arrow__path {
  stroke: #fff;
  fill: transparent;
  stroke-dasharray: 4 2;
}

.arrow__head line {
  stroke: #fff;
  stroke-width: 2px;
}
