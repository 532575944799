
.my-checklist-list {

  &__checklist-name {

  }

  &__checklist-worker, &__checklist-stat {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.41);
  }

  &__content{
    display: block;
  }

  &__item {
    color: white;
    padding: 9px 19px;
    background: #191B1E;
    margin-bottom: 5px;
    width: 570px;
    position: relative;

    .checklist-upcoming {
      position: absolute;
      left: calc(100% + 20px);
      top: 0;
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      transition: all 0.3s;
    }

    &:hover {
      .checklist-upcoming {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__checklist-item {
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    grid-gap: 10px;
  }

  &__checklist-stat {
    text-align: right;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__group-name {
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
  }

  &__nav {
    margin-top: 40px;
  }
}
