
.checklist-upcoming {
  background-color: #191B1E;
  width: 300px;
  padding: 10px 15px;

  &__title {
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 5px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
