.loading-status {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
    padding-right: 24px;
  }

  &:first-child {
    padding-left: 24px;
  }

  &__name {
    height: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  &__tasks {
    display: flex;
  }

  &__name-text {
    font-size: 15px;
    font-weight: 500;
    margin-right: 8px;
    color: #fff;
  }

  &__counter {
    color: #797C84;
    border-radius: 100px;
    background-color: #24262A;
    padding: 1px 5px 0;
    font-size: 12px;
  }

  &__tasks-item {
    width: 230px;
    flex: 0 0 230px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #080C11;
      color: #565B63;
      padding: 12px 0;
    }
  }
}