.theme-grey {
  .vdatetime-popup {
    &__header {
      background: #F1F3F8;
      color: #3A3E44;
    }
    &__actions__button {
      color: #6C707B;
    }
  }
  .vdatetime-calendar__month__day--selected > span > span {
    background: #6C707B;
    &:hover {
      background: #6C707B;
    }
  }
  .vdatetime-time-picker {
    &__item {
      color: #6C707B;
      &:hover {
        color: #000000;
        font-size: 20px;
      }
    }
    &__item--selected {
      color: #000000;
      font-size: 20px;
    }
  }
}

.datepicker {
  border: none;
  font-size: 17px;
  background: #F1F3F8;
  color: #6C707B;
}