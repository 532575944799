
.sidebar__item {
  .sidebar__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar__left{
    display: flex;
    align-items: center;
  }
  .router-link-active {
    background-color: #FF234C;

    .sidebar__count {
      background-color: #ffffff;
      color: #000000;
    }
  }
}
