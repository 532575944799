
.checklist-form {
  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    margin-bottom: 30px;
  }

  &__top {
    margin-bottom: 34px;
  }

  &__back {
    padding: 10px;
    margin: -10px;
    margin-right: 10px;
  }

  &__name {
    input {
      background-color: rgba(25, 27, 30, .6);
      padding: 9px 19px;
      color: white;
      border: none;
      font-size: 16px;
      margin-bottom: 30px;
      min-width: 570px;
    }
  }

  &__go, &__submit, &__go-submit-button {
    border-radius: 30px;
    color: white;

    background-color: #FF234C;
    border-color: #FF234C;
    font-size: 15px;
    height: 40px;
    padding: 0 20px;
  }

  &__go {
    background-color: #2340ff;
    border-color: #2340ff;
  }

  //need to be redone
  &__go {
    .checklist-form {
      &__button-text{
        padding: 5px 19px 8px;
      }
    }
  }

  &__button-text{
    display: flex;
    padding: 9px 19px 11px;
    align-items: center;
  }

  &__go-form {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    width: 570px;
  }

  &__go-line {
    margin-bottom: 25px;

    select {
      width: 400px;
      height: 30px;
      font-size: 14px;
    }
  }

  &__go-line-label {
    display: block;
    margin-bottom: 10px;
  }

  &__head-worker {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.41);
  }

  &__actions {
    position: relative;

    a {
      position: absolute;
      right: 0;
    }
  }
}
