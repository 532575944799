
.task-workers {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 11px;

  &__block {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    margin-right: 10px;
    font-size: 15px;
    color: #A9AEB9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}



.option {
  &__small {
    font-size: 15px;
    color: #3A3E44;
  }
}

.multiselect {
  &__input {
    font-size: 15px;
  }

  &__placeholder {
    font-size: 15px;
    color: #3A3E44;
  }

  &__tag {
    background: rgba(255, 35, 76, 0.8);
  }

  &__tag-icon {
    &:hover {
      background-color: #FF234C;
    }

    &::after {
      color: #ffffff;
    }
  }

  &__tags {
    font-size: 15px;
    border: none;
    color: #3A3E44;
  }

  &__content-wrapper {
    border: none;
    box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
  }

  &__option--highlight {
    background-color: #F1F3F8;
    color: #3A3E44;
  }
}


