.tasks-list {
  &__item.sortable-chosen {
    opacity: 1;
  }
  &__item.ghost {
    position: relative;
    opacity: 1;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: #000000;
    }
  }
}

body.drag-task {
  .tasks-list {
    &>div {
      min-height: calc(100vh - 265px);
    }
  }
}