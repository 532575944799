
.checklist-item {
  display: grid;
  grid-template-columns: 570px 1fr;
  grid-template-areas: "data actions";
  padding-bottom: 10px;

  &__textarea {
    margin-bottom: 0;
    width: 100%;

    textarea {
      background-color: rgba(25, 27, 30, .6);
      padding: 9px 19px;
      color: white;
      border: none;
      font-size: 16px;
      margin-bottom: 0;
      font-family: "Roboto", sans-serif;
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  &__data {
    grid-area: data;
    display: grid;
    grid-template-columns: 1fr;

    &._has-status {
      grid-template-columns: 30px 1fr;
    }
  }

  &__actions {
    grid-area: actions;
    position: relative;
    display: flex;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .checklist-item__actions {
      opacity: 1;
    }
  }

  &__action {
    padding: 13px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
    opacity: 0.4;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &__add, &__delete {
    position: relative;
    font-size: 0;
    width: 26px;
    height: 26px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &:before, &:after {
      position: absolute;
      content: "";
      width: 14px;
      height: 2px;
      background-color: white;
      top: calc(50% - 1px);
      left: calc(50% - 7px);
    }
  }

  &__add {

  }

  &__add {
    &:after {
      transform: rotate(90deg);
    }
  }

  &__delete {
    margin-left: 10px;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__textarea-wrapper {

  }

  &__status {
    padding-top: 8px;
  }

  &._checked {
    textarea {
      color: rgba(255, 255, 255, 0.35);
    }
  }
}
