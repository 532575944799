.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;

  //max-width: 100vw;
  overflow: hidden;

  &__content {

  }

  &__main {
    display: flex;
    min-height: 100vh;
  }
}