.loading-worker {
  margin-bottom: 30px;

  &__head {
    padding: 0 24px;
  }

  &__head-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #2B2C2F;
    padding-bottom: 8px;
  }

  &__who {
    display: flex;
    align-items: center;
  }

  &__name {
    padding-left: 14px;
    font-weight: 500;
    color: #fff;
  }

  &__tasks {
    overflow: hidden;
    position: relative;
  }

  &__tasks-container {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    margin-bottom: -40px;
  }

  &__inner {
    padding-top: 8px;
    display: flex;
  }

  &:last-child {
    margin-bottom: 0;
  }
}