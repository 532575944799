.time {
  &__filter {
    display: flex;
    align-items: center;
  }

  &__links {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &__link {
    margin: 0 6px;
    color: #fff;
    font-size: 12px;
  }

  &__dates {
    display: flex;
    align-items: center;

    .datepicker {
      padding: 4px 10px;
      max-width: 120px;
      font-size: 14px;
      text-align: center;
    }

    .vdatetime {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}