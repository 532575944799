
//@import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';
@import '../../../../../../node_modules/@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';

.rcp {
  width: 180px;
  height: 180px;
}
.rcp__palette:before {
  background: #191B1E;
}

.rcp__well {
  border: none;
  box-shadow: none;
}
