.task-repeater {
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  z-index: 100;
  border-bottom: 1px solid #EAECF2;
  padding: 25px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #EAECF2;
    margin-bottom: 15px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #A9AEB9;
  }

  &__row {
    margin-bottom: 13px;
  }

  &__label {
    font-size: 15px;
    color: #A9AEB9;
    margin-bottom: 4px;
  }

  &__base {
    .theme-grey {

      .vdatetime-input {
        border: 1px solid #DDE1EA;
        background-color: #fff;
        width: 100%;
        color: #3A3E44;
        font-size: 15px;
        padding: 5px 10px;
      }
    }
  }

  &__period-inputs {
    display: flex;
    align-items: center;
  }

  &__period-value-input, &__deadline-input {
    border: 1px solid #DDE1EA;
    font-size: 15px;
    padding: 5px 10px;
    width: 67px;
  }

  &__period-period-input {
    font-size: 15px;
    color: #3A3E44;
    appearance: none;
    border: none;
    background: transparent;
    padding-left: 3px;
    padding-right: 10px;

    background: {
      repeat: no-repeat;
      position: top 50% right 0;
      image: url('../images/svg/arrow-down-sm.svg')
    };
  }

  &__period-period {
    padding-left: 10px;
  }

  &__deadline-inputs {
    display: flex;
    align-items: center;
  }

  &__deadline-postfix {
    padding-left: 13px;
    font-size: 15px;
    color: #3A3E44;
  }

  &__button {
    width: 48%;
  }
}