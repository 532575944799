.timeline-head {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #191B1E;

  &__legend {

  }

  &__hour {
    font-size: calc(var(--timeline-hour) / 3.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__day-name {
    padding-top: 10px;
    padding-bottom: 6px;
    text-align: center;
    font-size: calc(var(--timeline-hour) / 2);
    box-shadow: inset 0 -1px 0 #2B2C2F;
  }
}