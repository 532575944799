
.v--modal {
    &-overlay {
        background: rgba(0, 0, 0, 0.7) !important;
    }
}

.fade-enter-active, .fade-leave-active {
    /*transition: opacity .02s;*/
    opacity: 1;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}

