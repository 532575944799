
/*.task-index__editable {*/
  /*textarea {*/
    /*font-family: 'Roboto';*/
    /*width: 100%;*/
    /*height: 264px;*/
    /*border: 1px solid #DDE1EA;*/
    /*resize: none;*/
    /*font-size: 15px;*/
    /*color: #6C707B;*/
    /*padding: 12px 14px 16px 14px;*/
    /*margin-bottom: 12px;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
  /*}*/
/*}*/
.editr {
  margin-bottom: 15px;
}
.editr--content {
  font-size: 15px;
  color: #6C707B;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 60px;

  &::before {
    font-size: 15px;
  }
}
