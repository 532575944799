.credentials {
  &__heading {
    color: #ffffff;
    font-weight: 500;
    font-size: 17px;
  }

  &__add {
    display: flex;
    padding: 6px 70px;
    align-items: center;
    justify-content: center;
    background: #191B1E;
    margin-top: 10px;
    max-width: 280px;
  }

  &__add-icon {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  &__add-title {
    color: rgba(255,255,255,0.53);
  }

  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    padding-bottom: 8px;
    margin-top: 10px;
    overflow: hidden scroll;
    max-height: calc(100vh - 249px);

    &._adding {
      min-height: 259px;
      overflow: hidden;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    padding: 6px 7px 4px;
    flex: 0 0 280px;
  }

  &__item-heading {
    display: flex;
  }

  &__item-name {
    display: inline-block;
    font-size: 13px;
    color: #9499A4;
    border-radius: 100px;
    background-color: #F1F3F8;
    font-weight: 500;
    padding: 2px 5px;
    margin-bottom: 5px;
  }

  &__item-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__item-main {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    font-size: 16px;
    font-weight: 400;
  }

  &__edit,
  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 26px;
    height: 26px;
    border: 1px solid #EAECF2;
    transition: background-color .3s;

    img {
      transition: all .3s;
    }

    &:hover {
      background-color: #FC585E;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &__remove {
    border-left: none;
  }

  &__edit-icon {
    display: flex;
    height: max-content;
  }

  &__remove-icon {
    display: flex;
    height: max-content;
    transform: rotate(45deg);
  }

  &__create-form {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #191B1E;
    max-width: 280px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      content: '';
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.78);
    }

    textarea {
      font-family: $font;
      width: 100%;
      font-size: 15px;
      line-height: 1.3em;
      border: none;
    }
  }

  &__create-area-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    background-color: #fff;
  }

  &__create-area-item {
    padding: 2px 8px;

    &:not(:first-child) {
      border-top: 1px solid $color-black;
    }

    &._errors {
      &::-webkit-input-placeholder {
        color: $color-alert;
      }
    }
  }

  &__create-form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
  }

  &__create-form-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    margin-left: 8px;
    flex: 0 0 auto;
  }

  &__create-form-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    margin-left: 8px;
    padding: 5px 16px;
    flex: 0 0 auto;
    background-color: $color-alert;
    border-radius: 1000px;
  }

  &__create-form-submit-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  &__host,
  &__url,
  &__login,
  &__password,
  &__text {
    cursor: copy;
  }

  &__item-value {
    position: relative;
    margin: 2px;

    &._copied {
      .credentials__item-value-notice-done {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }

    &._copy-error {
      .credentials__item-value-notice-error {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__item-value-notice-done,
  &__item-value-notice-error {
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      background-color: #fff;
      border-radius: 4px;
      padding: 4px 4px 1px;
      bottom: calc(100% + 2px);
      left: 0;
      border: 1px solid black;
      opacity: 0;
      transition: opacity $default-transition;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      bottom: calc(100% - 1px);
      left: 10px;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity $default-transition;
      pointer-events: none;
    }
  }

  &__item-value-notice-done {
    &::before {
      content: '✅ Скопировано!';
    }
  }

  &__item-value-notice-error {
    &::before {
      content: '❎ Ошибка!';
    }
  }
}