//.button, button {
//  padding: 0;
//  margin: 0;
//  text-align: center;
//  transition: $default-transition;
//  display: inline-block;
//  border: none;
//  background: $primary-color;
//  font-size: 16px;
//  cursor: pointer;
//
//  &.expand, &.wide {
//    width: 100%;
//  }
//
//  &.height {
//    height: $default-input-height;
//  }
//}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $color-alert;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    border: 1px solid #c1c1c1;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    display: block;
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
  }

}