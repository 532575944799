
.checklist-template-list {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__pipeline {
    display: flex;
  }

  &__name {
    color: white;
    padding: 9px 19px;
    background: #191B1E;
    margin-bottom: 5px;
    min-width: 560px;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    padding: 9px 19px;
    width: fit-content;
    margin-top: 15px;
  }
}
