.status {
  display: flex;
  align-items: center;
  padding-right: 15px;

  &__icon {
    margin-right: 6px;
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-dark-blue;
  }

  &__name {

  }
}