
@import '../../../../../scss/_settings/settings';

.TheFilter {
    display: flex;
    align-items: center;
    position: relative;

    &__avatars {
        position: relative;
        top: 2px;
        z-index: 12;
    }

    &__link {
        position: relative;
        display: inline-block;
        padding: 10px;
        margin-right: -10px;
        z-index: 20;
    }

    &__label {
        position: relative;
        color: #ffffff;
        margin-right: 3px;
    }

    &__select {
        background: #ffffff;
        position: absolute;
        width: 278px;
        right: 0;
        top: 50px;
        z-index: 10;
    }

    &__icon {
        display: inline-block;
        transition: transform .2s ease-in-out;
        transform-origin: 50% 50%;

        &_select-opened {
            transform: rotate(-180deg);
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;

    .app-modal__inner {
        transition: transform .3s;
    }
}

.fade-enter, .fade-leave-to {
    opacity: 0;

    .app-modal__inner {
        transform: translate3d(0, 20px, 0);
    }

}
