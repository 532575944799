.sidebar {
  position: relative;
  padding-top: 12px;
  background-color: $color-primary;
  min-width: 280px;
  height: 100vh;
  z-index: 10;

  &__head {
    align-items: center;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    display: block;
    padding: 11px 10px 10px;

    svg {
      display: block;
    }
  }

  &__closer {
    transform-origin: 50% 50%;
    transform: rotate(180deg) translateY(3px);
    padding: 10px;
    padding-left: 13px;
  }

  &__hamburger {
    display: flex;
    flex-wrap: wrap;
    width: 42px;
    padding: 9px 10px 10px 10px;

    &_closer {
      .sidebar__hamburger-line:nth-child(1) {
        transform: translate3d(0, 6px, 0) rotate(45deg);
      }
      .sidebar__hamburger-line:nth-child(2) {
        opacity: 0;
      }
      .sidebar__hamburger-line:nth-child(3) {
        transform: translate3d(0, -6px, 0) rotate(-45deg);
      }
    }
  }

  &__hamburger-line {
    display: flex;
    flex: 0 0 100%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__main {

  }

  &__search {
    justify-content: space-between;
    padding: 0 24px;
    display: flex;
    margin-bottom: 21px;
  }

  &__input {
    width: 100%;
    height: 37px;
    text-align: left;
    padding-left: 24px;
    background: {
      image: url("../svg/search_white.svg");
      repeat: no-repeat;
      position: left center;
      color: $color-primary;
    };
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    border: none;
    border-bottom: 1px solid rgba(255,255,255,0.16);

    &::placeholder {
      color: rgba(255,255,255,0.53);
    }
  }

  &__add {
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    letter-spacing: 0;
    box-shadow: 0 10px 7px 0 rgba(224, 230, 240, 0.20);
    border: 1px solid $color-gray-border2;
    border-radius: 100px;


    &:hover {
      background-color: $color-blue;
    }
  }

  &__all {
    margin-bottom: 15px;
  }

  &__item {

  }

  &__link {
    position: relative;
    padding: 6px 23px;
    display: flex;
    align-items: center;
    transition: all 0.4s;

    //transition: all $default-transition;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      background-color: $color-alert;
      opacity: 0;
      transition: all $default-transition;
    }

    &:hover {
      background-color: #24262A;

      &:before {
        opacity: 1;
      }
    }

    &_iconable {
      display: flex;
      align-items: center;
    }
  }

  &__link-icon {
    margin-right: 16px;

    svg, img {
      display: block;
      margin-bottom: 1px;
    }
  }

  &__link.active {
    background: $color-alert;
  }

  &__link.active &__count {
    background: #ffffff;
  }

  &__name {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 15px;
    margin-right: 7px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__count {
    background: #24262A;
    padding: 2px 5px 1px 5px;
    border-radius: 1000px;
    font-size: 12px;
    color: #797C84;
  }

  &__category {
    margin-bottom: 20px;

    &_collapsed {
      margin-bottom: 0;

      .sidebar__items {
        display: none;
      }

      .sidebar__group-arrow img {
        transform: rotate(0);
      }
    }
  }

  &__group-arrow {
    padding-left: 10px;
  }

  &__group-arrow img {
    transition: all 0.3s;
    transform: rotate(180deg);
  }

  &__title {
    display: flex;
    margin-bottom: 2px;
    padding: 10px 23px;
    font-weight: 500;
    color: $color-gray-name;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.07em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__items {

  }

  [data-simplebar] {
    height: calc(100vh - 120px);
    width: 100%;
  }
}
