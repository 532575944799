.membership-value {
  &__select {
    background-color: transparent;
    border: none;
    width: 100%;
    border-radius: 0;
    appearance: none;
    font-family: $font;
    color: #fff;
    padding: 0 10px;
    text-align: center;
    height: 30px;
  }

  &__type {
    &_10 {
      background-color: rgba(55, 125, 255, 0.35);
    }

    &_20 {
      background-color: #FF234C;
    }

    &_30 {
      background-color: #FF742E;
    }

    &_40 {
      background-color: #f12eff;
    }

    &_50 {
      background-color: rgba(116, 248, 207, 0.71);
    }
  }
}