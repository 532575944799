.multiselect {
  width: 250px;
  position: relative;

  &__option--selected {
    font-weight: 400;
    color: #3A3E44;
    background: {
      image: url("../svg/check-red-sm.svg");
      position: right 8% center;
      repeat: no-repeat;
      color: #ffffff;
    }
  }
  &__select {
    background-image: url("../svg/arrow-down-sm.svg");
    background-repeat: no-repeat;
    background-position: center center;
    &::before {
      border: none;
    }
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #F1F3F8;
  color: #3A3E44;
  background: {
    image: url("../svg/check-red-sm.svg");
    position: right 8% center;
    repeat: no-repeat;
  }
}