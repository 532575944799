.textarea {
  border: 1px solid #c1c1c1;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  font-size: 14px;
  width: 100%;
  display: block;
  font-family: $font;
  padding: 10px 10px;
}