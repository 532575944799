.fav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  &.in-fav{
    .fav-button{
      &__icon{
        svg{
          path{
            fill: #FFFFFF;
          }
        }
      }
    }
  }
  &__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    opacity: 0;
    &:hover{
      svg{
        path{
          stroke: #FFFFFF;
        }
      }
    }
    svg{
      path{
        transition: stroke 0.3s;
      }
    }
  }
}

.sidebar{
  &__link{
    &:hover{
      .fav-button__icon{
        opacity: 1;
      }
    }
  }
}
