.membership-container {
  padding-right: 20px;

  &__line {
    display: grid;
    grid-auto-flow: column;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #191B1E;

    .membership-container {
      &__line-values {

      }
    }
  }

  &__worker-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 10px 5px;
    font-size: 12px;
  }

  &__line-head {
    flex: 0 0 240px;
    position: sticky;
    left: 0;
    width: 240px;
    min-width: 240px;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 20px;
    box-shadow: inset -1px -1px 0 #2B2C2F;
    background-color: #191B1E;
    z-index: 120;
  }

  &__line-head-name {
    position: relative;
    display: inline-block;
    font-size: 15px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__line-values {
    display: grid;
    grid-auto-flow: column;
  }

  &__value {
    flex: 0 0 140px;
    width: 140px;
    text-align: center;

    box-shadow: inset -1px -1px 0 #2B2C2F;
  }
}