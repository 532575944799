
.auth-wrapper {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: {
        image: url('/static/images/base/tidalwaves.jpg');
        position: center;
        size: cover;
        repeat: no-repeat;
    }
}
